<rs-layout-wrapper>
  <div class="rs-verification-code">
    <p class="rs-top-label">
      {{ "text.we_just_emailed_you" | translate }}
    </p>
    <p class="rs-middle-label">
      {{ "text.please_enter_your_code" | translate }}
    </p>
    <p class="rs-verification-code__email">{{ email }}</p>
    <a class="rs-verification-code__change-email" routerLink="/edit-email">{{ "authentication.edit_email_address" | translate }}</a>
    <div class="rs-verification-code__form">
      <rs-verification-code-form></rs-verification-code-form>
    </div>
  </div>
</rs-layout-wrapper>
