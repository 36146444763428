import { Component } from '@angular/core';

@Component({
  selector: 'rs-verification-code',
  templateUrl: 'verification-code.component.html',
  styleUrls: ['verification-code.component.scss']
})
export class VerificationCodeComponent {
  //TODO: Remove hardcode value
  public email: string = 'jason@gmail.com';
}
