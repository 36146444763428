import { NgModule } from '@angular/core';

import { DirectivesModule } from '../../directives/directives.module';
import { SharedModule } from '../../modules/shared.module';
import { VerificationCodeFormComponent } from './component/verification-code-form.component';

@NgModule({
  imports: [
    SharedModule,
    DirectivesModule
  ],
  declarations: [
    VerificationCodeFormComponent
  ],
  exports: [
    VerificationCodeFormComponent
  ]
})
export class VerificationCodeFormModule {}
