import { environment } from 'src/environments/environment';

export const NOTIFICATION_TYPES = {
  DEFAULT: 'default',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error'
};

export const TERM_OF_USE_LINK: string = 'https://www.rockspoon.com/hubfs/Documents/Terms_of_Use.pdf';
export const PRIVACY_POLICY_LINK: string = 'https://www.rockspoon.com/hubfs/Documents/Privacy_Policy.pdf';

export const COMMON_CONSTANTS = {
  API_URL: environment.apiUrl,
  DEFAULT_LANGUAGE: 'en'
};

//TODO: Remove hardcoded values
export const JOB_POSITIONS: string[] = [
  'Owner',
  'Manager',
  'Chef',
  'Office Administrator',
  'Server/Bartender'
];

//TODO: Remove hardcoded values
export const USER_NAME: string = 'Jason';

export const MOBILE_PREFIX = '+1';

export const MOBILE_MASK = '(000) 000-0000';

export const HEADERS = {
  KEY: 'key',
  ACCESS_TOKEN: 'access_token'
};

export const DIALOG_SIZES = {
  MIDDLE: '1000px',
  SMALL: '700px'
};
