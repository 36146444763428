<rs-layout-wrapper>
  <div class="rs-mobile-number">
    <p class="rs-top-label">
      {{ "text.lets_keep_your_information_safe" | translate }}
      <mat-icon>lock</mat-icon>
    </p>
    <p class="rs-middle-label">{{ "text.please_enter_your_mobile_number" | translate }}</p>
    <rs-phone-control (isControlValid)="setMobileNumberControlAsValid()"></rs-phone-control>
    <a href="" *ngIf="isMobileNumberControlValid" class="rs-link">{{ "authentication.edit_mobile_number" | translate }}</a>
    <rs-verification-code-form *ngIf="isMobileNumberControlValid"></rs-verification-code-form>
  </div>
</rs-layout-wrapper>
