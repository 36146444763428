<div
  class="rs-password-strength"
  [ngClass]="{
    'rs-password-strength_weak': isPasswordWeak(),
    'rs-password-strength_moderate': isPasswordModerate(),
    'rs-password-strength_strong': isPasswordStrong(),
    'rs-password-strength_very-strong': isPasswordVeryStrong(),
    'rs-password-strength_complete': isPasswordComplete()
  }"
>
  <div class="rs-password-strength__title">{{'password_strength.your_password_needs' | translate}}</div>
  <div class="rs-password-strength__section">
    <div class="rs-password-strength__section-item">
      <span [class.rs-password-strength__section-item_completed]="nineCharactersCheckCompleted">{{'password_strength.characters' | translate}}</span>
      <span>{{'password_strength.of_which' | translate}}:</span>
    </div>
  <div class="rs-password-strength__section">
    <div class="rs-password-strength__section-item" [class.rs-password-strength__section-item_completed]="lowerCaseCheckCompleted">{{'password_strength.lower_case' | translate}}</div>
    <div class="rs-password-strength__section-item" [class.rs-password-strength__section-item_completed]="upperCaseCheckCompleted">{{'password_strength.upper_case' | translate}}</div>
    <div class="rs-password-strength__section-item" [class.rs-password-strength__section-item_completed]="numberCheckCompleted">{{'password_strength.number' | translate}}</div>
    <div class="rs-password-strength__section-item" [class.rs-password-strength__section-item_completed]="specialCharacterCompleted">{{'password_strength.special_character' | translate}}</div>
  </div>
  <div class="rs-password-strength__title">{{'password_strength.password_strength' | translate}}:</div>
  <div class="rs-password-strength__value">
    <div class="rs-password-strength__value-line">
      <div class="rs-password-strength__value-line-filler"></div>
    </div>
    <div class="rs-password-strength__value-title" *ngIf="isPasswordWeak()">{{'password_strength.weak' | translate}}</div>
    <div class="rs-password-strength__value-title" *ngIf="isPasswordModerate()">{{'password_strength.moderate' | translate}}</div>
    <div class="rs-password-strength__value-title" *ngIf="isPasswordStrong()">{{'password_strength.strong' | translate}}</div>
    <div class="rs-password-strength__value-title" *ngIf="isPasswordVeryStrong()">{{'password_strength.very_strong' | translate}}</div>
    <div class="rs-password-strength__value-title" *ngIf="isPasswordComplete()">{{'password_strength.complete' | translate}}</div>
  </div>
  <div class="rs-password-strength__arrow"></div>
</div>
