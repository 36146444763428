import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';
import { NotifierModule, NotifierOptions } from 'angular-notifier';

import { AuthState } from 'src/auth/ngxs/auth.state';
import { SpinnerState } from '../components/spinner/ngxs/spinner.state';
import { TranslateModuleConfig } from '../config/translate';

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'bottom',
      distance: 12,
      gap: 10
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};

@NgModule({
  imports: [
    NgxsModule.forRoot([
      AuthState,
      SpinnerState
    ]),
    TranslateModule.forRoot(TranslateModuleConfig),
    NotifierModule.withConfig(customNotifierOptions)
  ],
  exports: [
    NotifierModule,
    NgxsModule
  ]
})
export class PluginsModule {}
