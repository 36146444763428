import { Component } from '@angular/core';

import { PRIVACY_POLICY_LINK, TERM_OF_USE_LINK } from 'src/auth/shared/constants/common';

@Component({
  selector: 'rs-layout-wrapper',
  templateUrl: 'layout-wrapper.component.html',
  styleUrls: ['layout-wrapper.component.scss']
})
export class LayoutWrapperComponent {

  public readonly termOfUse: string = TERM_OF_USE_LINK;
  public readonly privacyPolicy: string = PRIVACY_POLICY_LINK;

}
