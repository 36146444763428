import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { Store } from '@ngxs/store';

import { NotifierService } from 'angular-notifier';
import { RecoverPassword } from 'src/auth/ngxs/auth.actions';
import { NOTIFICATION_TYPES } from 'src/auth/shared/constants/common';
import { PAGES } from 'src/auth/shared/constants/pages';
import { REGEX } from 'src/auth/shared/constants/regular-expressions';
import { RecoverPasswordData } from 'src/auth/shared/models/reset-password.model';
import { RouterService } from 'src/auth/shared/services/router.service';
import { confirmPasswordValidator } from 'src/auth/shared/validators/confirm-password.validation';


@Component({
  selector: 'rs-reset-password',
  templateUrl: 'reset-password.component.html',
  styleUrls: ['reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  public resetPasswordForm: FormGroup;

  private token: string;

  constructor(
    private route: ActivatedRoute,
    private notifierService: NotifierService,
    private routerService: RouterService,
    private formBuilder: FormBuilder,
    private store: Store,
  ) {}

  ngOnInit() {
    this.getResetToken();
    this.initForm();
  }

  public resetPassword(): void {
    if (this.resetPasswordForm.valid) {
      const { password } = this.resetPasswordForm.getRawValue();

      this.store.dispatch(new RecoverPassword(new RecoverPasswordData(password, this.token)));
    }
  }

  private getResetToken(): void {
    this.token = this.route.snapshot.queryParams.token;

    if (!this.token) {
      this.notifierService.notify(NOTIFICATION_TYPES.ERROR, 'Your token is incorrect.');
      this.routerService.navigateToPage(PAGES.signIn);
    }
  }

  private initForm(): void {
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.pattern(REGEX.PASSWORD)]],
      confirm: ['', Validators.required]
    }, {
      validators: confirmPasswordValidator
    });
  }

}
