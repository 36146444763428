import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MatInput } from '@angular/material/input';
import { Unsubscribe } from 'src/auth/shared/classes/unsubscribe.class';

@Component({
  selector: 'rs-verification-code-form',
  templateUrl: 'verification-code-form.component.html',
  styleUrls: ['verification-code-form.component.scss']
})
export class VerificationCodeFormComponent extends Unsubscribe implements OnInit, AfterViewInit {

  @ViewChild('digit_1') digit_1: MatInput;
  @ViewChild('digit_2') digit_2: MatInput;
  @ViewChild('digit_3') digit_3: MatInput;
  @ViewChild('digit_4') digit_4: MatInput;

  public authenticationCodeForm: FormGroup;
  public isFormIncomplete: boolean = false;
  public isCodeIncorrect: boolean = false;

  constructor(private readonly formBuilder: FormBuilder){
    super();
  }

  public ngOnInit(): void {
    this.initForm();
    this.subscribeForm();
  }

  public ngAfterViewInit(): void {
    this.digit_1.focus();
  }

  private initForm(): void {
    this.authenticationCodeForm = this.formBuilder.group({
      digit_1: [null, [Validators.required]],
      digit_2: [null, [Validators.required]],
      digit_3: [null, [Validators.required]],
      digit_4: [null, [Validators.required]]
    });
  }

  private subscribeForm(): void {
    this.authenticationCodeForm.valueChanges
      .subscribe( form => {

        if (parseInt(form.digit_1)) {
          this.digit_2.focus();
        }

        if (parseInt(form.digit_2)) {
          this.digit_3.focus();
        }

        if (parseInt(form.digit_3)) {
          this.digit_4.focus();
        }

        this.isFormIncomplete = !!Object.values(form).filter( (value:any) => !parseInt(value)).length;
      });
  }

}
