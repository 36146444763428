import { Component } from '@angular/core';

@Component({
  selector: 'rs-mobile-number',
  templateUrl: 'mobile-number.component.html',
  styleUrls: ['mobile-number.component.scss']
})
export class MobileNumberComponent {

  public isMobileNumberControlValid: boolean = false;

  public setMobileNumberControlAsValid(): void {
    this.isMobileNumberControlValid = true;
  }

}
