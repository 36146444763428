<rs-layout-wrapper>
  <div class="rs-sign-in-already-user">
    <p>{{ "text.already_user" | translate : {name: userName} }}</p>
    <div class="rs-sign-in-already-user__form">
      <form [formGroup]="alreadyUserForm" class="rs-form-control-outline">
        <mat-form-field appearance="outline">
          <mat-label>{{"placeholders.restaurant_name_full" | translate }}</mat-label>
          <input formControlName="restaurantName" matInput [placeholder]="'placeholders.restaurant_name' | translate">
          <mat-icon matSuffix>info</mat-icon>
          <mat-error *ngIf="isInvalidControl('restaurantName') && alreadyUserForm.controls['restaurantName'].errors.required">
            {{ "validation.required" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{"placeholders.job_position" | translate }}</mat-label>
          <mat-select formControlName="position" [placeholder]="'placeholders.job_position' | translate" class="rs-form-select">
            <mat-option *ngFor="let position of positions" value="position">{{ position }}</mat-option>
          </mat-select>
          <mat-error *ngIf="isInvalidControl('position') && alreadyUserForm.controls['position'].errors.required">
            {{ "validation.required" | translate }}
          </mat-error>
        </mat-form-field>
      </form>
      <button class="rs-blue-button" mat-raised-button>
        {{ "placeholders.next" | translate | uppercase }}
      </button>
    </div>
  </div>
</rs-layout-wrapper>
