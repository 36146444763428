<div class="rs-sign-up">
  <div class="rs-sign-up-header">
    <div class="rs-sign-up-header__logo"></div>
  </div>
  <div class="rs-sign-up-content">
    <div class="rs-sign-up-content__form">
      <p>{{ "titles.sign_up" | translate }}</p>
      <form [formGroup]="signUpForm" class="rs-form-control-linear">
        <mat-form-field>
          <mat-label>{{"placeholders.first_name" | translate }}</mat-label>
          <input formControlName="firstName" matInput [placeholder]="'placeholders.first_name' | translate">
          <mat-error *ngIf="isInvalidControl('firstName') && signUpForm.controls['firstName'].errors.required">
            {{ "validation.required" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{"placeholders.last_name" | translate }}</mat-label>
          <input formControlName="lastName" matInput [placeholder]="'placeholders.last_name' | translate">
          <mat-error *ngIf="isInvalidControl('lastName') && signUpForm.controls['lastName'].errors.required">
            {{ "validation.required" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{"placeholders.email" | translate }}</mat-label>
          <input formControlName="email" matInput [placeholder]="'placeholders.email' | translate">
          <mat-error *ngIf="isInvalidControl('email') && signUpForm.controls['email'].errors.required">
            {{ "validation.required" | translate }}
          </mat-error>
          <mat-error *ngIf="isInvalidControl('email') && signUpForm.controls['email'].errors.pattern">
            {{ "validation.email_pattern" | translate }}
          </mat-error>
        </mat-form-field>
      </form>
      <button [disabled]="signUpForm.invalid" class="rs-blue-button rs-sign-up-content__next-btn" mat-raised-button (click)="reactOnSignUp()">
        {{"placeholders.next" | translate | uppercase }}
      </button>
      <p>{{"text.by_clicking_next" | translate }}</p>
      <a href="">{{"text.terms_of_service_and_privacy_policy" | translate }}</a>
      <p class="rs-sign-up-content__form-have-account">
        {{"text.have_account" | translate }}
      </p>
      <button class="rs-blue-button rs-sign-up-content__form-login" mat-raised-button routerLink="/sign-in">
        {{"placeholders.login" | translate | uppercase }}
      </button>
    </div>
    <div class="rs-sign-up-content__info">
      <div class="rs-sign-up-content__info-dash"></div>
      {{ "sign_up_page.online_hr_system" | translate }}
    </div>
  </div>
  <div class="rs-sign-up-footer"></div>
</div>
