import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { LayoutWrapperModule } from 'src/auth/shared/components/layout-wrapper/layout-wrapper.module';
import { SharedModule } from 'src/auth/shared/modules/shared.module';
import { EditEmailComponent } from './component/edit-email.component';

@NgModule({
  imports: [
    SharedModule,
    LayoutWrapperModule,
    RouterModule,
  ],
  declarations: [
    EditEmailComponent
  ],
  exports: [
    EditEmailComponent
  ]
})
export class EditEmailModule {}
