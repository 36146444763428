import { NgModule } from '@angular/core';

import { SharedModule } from '../../modules/shared.module';
import { LayoutWrapperComponent } from './component/layout-wrapper.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    LayoutWrapperComponent
  ],
  exports: [
    LayoutWrapperComponent
  ]
})
export class LayoutWrapperModule {}
