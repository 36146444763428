import { NgModule } from '@angular/core';

import { LayoutWrapperModule } from 'src/auth/shared/components/layout-wrapper/layout-wrapper.module';
import { PasswordControlModule } from 'src/auth/shared/components/password-control/password-control.module';
import { SharedModule } from 'src/auth/shared/modules/shared.module';
import { ResetPasswordComponent } from './reset-password.component';

@NgModule({
  imports: [
    SharedModule,
    PasswordControlModule,
    LayoutWrapperModule
  ],
  declarations: [
    ResetPasswordComponent
  ],
  exports: [
    ResetPasswordComponent
  ]
})
export class ResetPasswordModule {}
