import { NgModule } from '@angular/core';

import { Ng2TelInputModule } from 'ng2-tel-input';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from '../../modules/shared.module';
import { PhoneControlComponent } from './component/phone-control.component';


@NgModule({
  imports: [
    SharedModule,
    Ng2TelInputModule,
    NgxMaskModule.forRoot()
  ],
  declarations: [
    PhoneControlComponent
  ],
  exports: [
    PhoneControlComponent
  ]
})
export class PhoneControlModule {}
