import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { LayoutWrapperModule } from 'src/auth/shared/components/layout-wrapper/layout-wrapper.module';
import { SharedModule } from 'src/auth/shared/modules/shared.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SignInAlreadyUserComponent } from './sign-in-already-user/sign-in-already-user.component';
import { SignInComponent } from './sign-in-form/sign-in.component';

@NgModule({
  imports: [
    SharedModule,
    LayoutWrapperModule,
    RouterModule
  ],
  declarations: [
    SignInComponent,
    SignInAlreadyUserComponent,
    ForgotPasswordComponent
  ],
  exports: [
    SignInComponent,
    SignInAlreadyUserComponent,
    ForgotPasswordComponent
  ]
})
export class SignInModule {}
