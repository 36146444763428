<div class="rs-verification-code-form">
  <div class="rs-verification-code-form__form">
    <p class="rs-verification-code-form__form-label">
      {{ "authentication.authentication_code" | translate }}
    </p>
    <form [formGroup]="authenticationCodeForm" class="rs-authentication-code-input rs-form-control-linear">
      <mat-form-field>
        <input matInput maxlength="1" formControlName="digit_1" #digit_1="matInput" rsNumbersOnly>
      </mat-form-field>
      <mat-form-field>
        <input matInput maxlength="1" formControlName="digit_2" #digit_2="matInput" rsNumbersOnly>
      </mat-form-field>
      <mat-form-field>
        <input matInput maxlength="1" formControlName="digit_3" #digit_3="matInput" rsNumbersOnly>
      </mat-form-field>
      <mat-form-field>
        <input matInput maxlength="1" formControlName="digit_4" #digit_4="matInput" rsNumbersOnly>
      </mat-form-field>
    </form>
    <div
      *ngIf="isFormIncomplete || isCodeIncorrect"
      class="rs-verification-code__validation"
      [ngClass]="{
        'rs-verification-code__validation-incorrect-code': isCodeIncorrect,
        'rs-verification-code__validation-incomplete-form': isFormIncomplete && !isCodeIncorrect
      }"
    >
      <mat-icon>warning</mat-icon>
      <p *ngIf="isCodeIncorrect">
        {{ "validation.invalid_code" | translate }}
      </p>
      <p *ngIf="isFormIncomplete && !isCodeIncorrect">
        {{ "validation.must_enter_all_4_digits" | translate }}
      </p>
    </div>
    <div class="rs-verification-code-form__not-recieved">
      <p>
        {{ "authentication.in_case_you_did_not_receive_email" | translate }}
      </p>
      <a href="">{{ "authentication.resend_the_code" | translate }}</a>
    </div>
  </div>
</div>
