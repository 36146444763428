import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ModulesModule } from './modules/modules.module';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthComponent } from './auth.component';
import { PluginsModule } from './shared/modules/plugins.module';
import { SpinnerModule } from './shared/components/spinner/spinner.module';
import { ErrorHandlerInterceptor } from './shared/interceptors/error-handler-interceptor';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AuthRoutingModule,
    ModulesModule,
    PluginsModule,
    SpinnerModule
  ],
  declarations: [
    AuthComponent
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true}
  ],
  bootstrap: [
    AuthComponent
  ]
})
export class AuthModule {}
