import { Injectable } from '@angular/core';

import { CACHE } from '../enums/cache';
import { AuthenticationData } from '../models/authentication.model';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  constructor(
    private localStorageService: LocalStorageService
  ) {}

  get redirectTo(): string {
    return this.localStorageService.getCachedData(CACHE.REDIRECT_TO);
  }

  set redirectTo(redirectTo: string) {
    this.localStorageService.cacheData(CACHE.REDIRECT_TO, redirectTo);
  }

  get authenticationData(): AuthenticationData {
    return this.localStorageService.getCachedData(CACHE.AUTHENTICATION);
  }

  set authenticationData(authenticationData: AuthenticationData) {
    this.localStorageService.cacheData(CACHE.AUTHENTICATION, authenticationData);
  }

}
