import { Injectable } from '@angular/core';

import { Action, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { RecoverPassword, ResetPassword, SignIn, SignUp } from './auth.actions';
import { AuthService } from 'src/auth/auth.service';
import { SharedDataService } from '../shared/services/shared-data.service';
import { SetSpinnerVisibility } from '../shared/components/spinner/ngxs/spinner.action';
import { AuthenticationData } from '../shared/models/authentication.model';
import { environment } from 'src/environments/environment';
import { RouterService } from '../shared/services/router.service';
import { PAGES } from '../shared/constants/pages';
import { NotifierService } from 'angular-notifier';
import { NOTIFICATION_TYPES } from '../shared/constants/common';

@State({
  name: 'authentication'
})
@Injectable()
export class AuthState {

  constructor(
    private authService: AuthService,
    private sharedDataService: SharedDataService,
    private store: Store,
    private routerService: RouterService,
    private notifierService: NotifierService
  ) {}

  @Action(SignIn)
  signIn(stateContext, {authentication}: SignIn) {
    this.store.dispatch(new SetSpinnerVisibility(true));

    return this.authService.signIn(authentication)
      .pipe(
        tap({
          next: (authenticationData: AuthenticationData) => {
            this.sharedDataService.authenticationData = authenticationData;
            document.cookie = `refreshToken=${authenticationData.refreshToken};domain=${environment.rockspoonDomain}`;
            location.replace(this.sharedDataService.redirectTo);
          },
          complete: () => this.store.dispatch(new SetSpinnerVisibility(false))
        })
      );
  }

  @Action(RecoverPassword)
  recoverPassword(stateContext, {recoverPassword}: RecoverPassword) {
    this.store.dispatch(new SetSpinnerVisibility(true));

    return this.authService.recoverPassword(recoverPassword)
      .pipe(
        tap({
          next: () => {
            this.notifierService.notify(NOTIFICATION_TYPES.SUCCESS, 'Your password was changed successfully.');
            this.routerService.navigateToPage(PAGES.signIn);
          },
          complete: () => this.store.dispatch(new SetSpinnerVisibility(false))
        })
      );
  }

  @Action(ResetPassword)
  resetPassword(stateContext: StateContext<any>, {email}: ResetPassword) {
    this.store.dispatch(new SetSpinnerVisibility(true));

    return this.authService.resetPassword(email)
      .pipe(
        tap({
          next: () => {
            this.notifierService.notify(NOTIFICATION_TYPES.SUCCESS, 'We have sent message with reset password link on your email.');
            this.routerService.navigateToPage(PAGES.signIn);
          },
          complete: () => this.store.dispatch(new SetSpinnerVisibility(false))
        })
      );
  }

  // @Action(RefreshSession)
  // refreshSession() {
  //   this.showSpinner();

  //   return this.authenticationService.refreshToken(this.sharedDataService.authenticationData.refreshToken)
  //     .pipe(
  //       tap({
  //         next: (authenticationData: AuthenticationData) => this.sharedDataService.authenticationData = authenticationData,
  //         complete: () => this.hideSpinner()
  //       })
  //     );
  // }

  // @Action(Logout)
  // logout() {
  //   this.routerService.navigateToPage(PAGES.SIGN_IN);
  //   this.sharedDataService.clearUserData();
  //   this.store.dispatch(new ClearStepStatuses());
  // }

  // @Action(SendActivationLink)
  // sendActivationLink(stateContext, {email}: SendActivationLink) {
  //   this.showSpinner();

  //   return this.authenticationService.sendActivationLink(email)
  //     .pipe(
  //       tap({
  //         next: () => this.showNotificationsService.showFollowResendActivationLink(),
  //         complete: () => this.hideSpinner()
  //       })
  //     );
  // }

  @Action(SignUp)
  signUp(stateContext, {signUpData}: SignUp) {
    window.location.replace(`${environment.accountsUrl}?continue=${environment.authUrl}&email=${signUpData.email}&firstName=${signUpData.firstName}&lastName=${signUpData.lastName}`);
  }

}
