export const REGEX = {
  EMAIL: /^[a-z0-9.\-_+]+@[a-z0-9\-_+]+\.[a-z0-9. \-_+]+$/i,
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  EIGHT_CHARACTERS: /[a-zA-Z\d@$#!%*?&^()-=_+]{8,}/,
  NUMBER: /(?=.*\d)/,
  SPECIAL_SYMBOL: /[!@#$%^&*(),.?":{}|<>]/,
  LETTER: /(?=.*[a-zA-Z])/,
  LINK: /^[a-z0-9\-_+:/]+\.[a-z0-9. \-_+]+$/i,
  UPPER_CASE: /(?=.*[A-Z])/,
  LOWER_CASE: /(?=.*[a-z])/,
  NINE_CHARACTERS: /[a-zA-Z\d@$#!%*?&^()-=_+]{9,}/
}
