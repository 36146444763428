import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { Store } from '@ngxs/store';

import { SignIn } from 'src/auth/ngxs/auth.actions';
import { REGEX } from 'src/auth/shared/constants/regular-expressions';
import { SharedDataService } from 'src/auth/shared/services/shared-data.service';

@Component({
  selector: 'rs-sign-in',
  templateUrl: 'sign-in.component.html',
  styleUrls: ['sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  public signInForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private sharedDataService: SharedDataService,
    private activatedRoute: ActivatedRoute,
    private store: Store
  ){}

  public ngOnInit(): void {
    this.getRedirectionUrl();
    this.initForm();
  }

  public signIn(): void {
    if (this.signInForm.valid) {
      this.store.dispatch(new SignIn(this.signInForm.value));
    }
  }

  public isInvalidControl(controlName: string): boolean {
    const control = this.signInForm.get(controlName);
    return control.invalid && (control.dirty || control.touched);
  }

  private initForm(): void {
    this.signInForm = this.formBuilder.group({
      username: [null, [Validators.required, Validators.pattern(REGEX.EMAIL)]],
      password: [null, [Validators.required]]
    });
  }

  private getRedirectionUrl(): void {
    const { redirectTo } = this.activatedRoute.snapshot.queryParams;

    if (redirectTo) {
      this.sharedDataService.redirectTo = redirectTo;
    }
  }

}
