<rs-layout-wrapper>
  <div class="rs-sign-in">
    <form [formGroup]="signInForm" class="rs-form-control-outline">
      <mat-form-field appearance="outline">
        <mat-label>{{"placeholders.email" | translate }}</mat-label>
        <input (keydown.enter)="signIn()" formControlName="username" matInput [placeholder]="'placeholders.email' | translate">
        <mat-error *ngIf="isInvalidControl('username') && signInForm.controls['username'].errors.required">
          {{ "validation.required" | translate }}
        </mat-error>
        <mat-error *ngIf="isInvalidControl('username') && signInForm.controls['username'].errors.pattern">
          {{ "validation.email_pattern" | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{"placeholders.password" | translate }}</mat-label>
        <input (keydown.enter)="signIn()" formControlName="password" type="password" matInput [placeholder]="'placeholders.password' | translate">
        <mat-error *ngIf="isInvalidControl('password') && signInForm.controls['password'].errors.required">
          {{ "validation.required" | translate }}
        </mat-error>
        <mat-error *ngIf="isInvalidControl('password') && signInForm.controls['password'].errors.pattern">
          {{ "validation.password_pattern" | translate }}
        </mat-error>
      </mat-form-field>
    </form>
    <button class="rs-blue-button" [disabled]="signInForm.invalid" mat-raised-button (click)="signIn()">
      {{ "placeholders.login" | translate | uppercase }}
    </button>
    <a routerLink="/forgot-password">{{ "authentication.forgot_your_password" | translate }}</a>
    <p>{{ "text.dont_have_account" | translate }}</p>
    <button class="rs-blue-button" mat-raised-button routerLink="/sign-up">
      {{ "titles.sign_up" | translate | uppercase }}
    </button>
  </div>
</rs-layout-wrapper>
