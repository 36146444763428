import { HttpClient } from '@angular/common/http';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ERRORS } from './constants/errors';
import { Error } from './models/error.model';

export const copy = (obj: any) => JSON.parse(JSON.stringify(obj));

export const HttpLoaderFactory = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const getErrorContentByCode = (error: {code: number, message: string}) => {
  const errorDescription: Error = ERRORS.find((currentError: Error) => currentError.code === error.code);

  return errorDescription?.errorContent ? errorDescription?.errorContent : error.message;
}

export const getErrorTitleByCode = (errorCode: number) => {
  const error: Error = ERRORS.find((currentError: Error) => currentError.code === errorCode);

  return error?.errorTitle;
}
