<rs-layout-wrapper>
  <div class="rs-forgot-password">
    <div class="rs-forgot-password__title">{{'forgot_password.title' | translate}}</div>
    <div class="rs-forgot-password__description">{{'forgot_password.description' | translate}}</div>
    <div class="rs-forgot-password__control">
      <mat-form-field>
        <mat-label>{{'placeholders.email' | translate}}</mat-label>
        <input (keydown.enter)="sendEmail()" [formControl]="emailControl" matInput placeholder="{{'placeholders.email' | translate}}">
      </mat-form-field>
    </div>
    <div class="rs-forgot-password__button">
      <button [disabled]="emailControl.invalid" (click)="sendEmail()" mat-raised-button color="primary" class="app__button">{{'forgot_password.reset_my_password' | translate}}</button>
    </div>
  </div>
</rs-layout-wrapper>
