import { Error } from '../models/error.model';

export const ERRORS: Error[] = [
  {
    code: 4701009,
    errorTitle: 'errors.credentials.title',
    errorContent: 'errors.credentials.content'
  },
  {
    code: 4702075,
    errorTitle: 'errors.sign-up.title',
    errorContent: 'errors.sign-up.content'
  },
  {
    code: 4702077,
    errorTitle: 'errors.profile-activation.title',
    errorContent: 'errors.profile-activation.content'
  },
  {
    code: 4751010,
    errorTitle: 'errors.access-denied.title',
    errorContent: 'errors.access-denied.content'
  }
]

export const ERROR_CODES = {
  PROFILE_IS_NOT_ACTIVE: 4701010,
  PROFILE_IS_ALREADY_ACTIVATED: 4702077
}

export const ERROR_STATUSES = {
  UNAUTHORIZED: 401
}
