<rs-layout-wrapper>
  <div class="rs-password">
    <p class="rs-top-label">{{ "authentication.please_create_your_password" | translate }}</p>
    <form [formGroup]="passwordForm" class="rs-form-control-outline">

      <mat-form-field appearance="outline">
        <mat-label>{{"placeholders.password" | translate }}</mat-label>
        <input #password_1 formControlName="password_1" matInput autocomplete="off">
        <mat-error *ngIf="isInvalidControl('password_1') && passwordForm.controls['password_1'].errors.required">
          {{ "validation.required" | translate }}
        </mat-error>
        <div class="rs-password__mask" (click)="setInput1AsFocused('password_1')" *ngIf="restricted_password_1.length">
          <div class="rs-password__mask-item" *ngFor="let i of restricted_password_1.split('')"></div>
        </div>
      </mat-form-field>

      <div
        class="rs-password__validation"
        [ngClass]="{
          'rs-password__weak': isPasswordWeak(),
          'rs-password__moderate': isPasswordModerate(),
          'rs-password__strong': isPasswordStrong(),
          'rs-password__very-strong': isPasswordVeryStrong()
        }"
      >
        <div class="rs-password__validation-title" *ngIf="restricted_password_1">
          <div class="rs-password__validation-title-value" *ngIf="isPasswordWeak()">
            {{ "password_strength.weak" | translate | uppercase }}
          </div>
          <div class="rs-password__validation-title-value" *ngIf="isPasswordModerate()">
            {{ "password_strength.moderate" | translate | uppercase }}
          </div>
          <div class="rs-password__validation-title-value" *ngIf="isPasswordStrong()">
            {{ "password_strength.strong" | translate | uppercase }}
          </div>
          <div class="rs-password__validation-title-value" *ngIf="isPasswordVeryStrong()">
            {{ "password_strength.very_strong" | translate | uppercase }}
          </div>
          <div class="rs-password__validation-title-filler">
            <div></div>
          </div>
        </div>
        <div class="rs-password__validation-conditions" *ngIf="restricted_password_1">
          <div class="rs-password__validation-conditions-condition">
            <div [class.rs-password__validation-conditions-condition-complete]="isPasswordLengthCompleted">
              <mat-icon>check</mat-icon>
            </div>
            <div>{{ "password_strength.use_at_least_8_characters" | translate }}</div>
          </div>
          <div class="rs-password__validation-conditions-condition">
            <div [class.rs-password__validation-conditions-condition-complete]="isPasswordLetterCompleted">
              <mat-icon>check</mat-icon>
            </div>
            <div>{{ "password_strength.include_at_least_1_letter" | translate }}</div>
          </div>
          <div class="rs-password__validation-conditions-condition">
            <div [class.rs-password__validation-conditions-condition-complete]="isPasswordNumberCompleted">
              <mat-icon>check</mat-icon>
            </div>
            <div>{{ "password_strength.include_at_least_1_number" | translate }}</div>
          </div>
          <div class="rs-password__validation-conditions-condition">
            <div [class.rs-password__validation-conditions-condition-complete]="isPasswordSymbolCompleted">
              <mat-icon>check</mat-icon>
            </div>
            <div>{{ "password_strength.include_at_least_1_symbol" | translate }}</div>
          </div>
        </div>

      </div>

      <mat-form-field appearance="outline">
        <mat-label>{{"placeholders.confirm_password" | translate }}</mat-label>
        <input #password_2 formControlName="password_2" matInput (input)="checkPasswordsEqual()" autocomplete="off">
        <mat-error *ngIf="isInvalidControl('password_2') && passwordForm.controls['password_2'].errors.required">
          {{ "validation.required" | translate }}
        </mat-error>
        <div class="rs-password__equal" *ngIf="passwordsInequality">
          <mat-icon>warning</mat-icon>
          {{ "validation.the_two_passwords_dont_match" | translate }}
        </div>
        <div class="rs-password__mask" (click)="setInput1AsFocused('password_2')" *ngIf="restricted_password_2.length">
          <div class="rs-password__mask-item" *ngFor="let i of restricted_password_2.split('')"></div>
        </div>
      </mat-form-field>

    </form>
    <button
      mat-raised-button
      class="rs-blue-button"
      [disabled]="isFormComplete()"
    >
      {{ "placeholders.create_password" | translate | uppercase }}
    </button>
  </div>
</rs-layout-wrapper>
