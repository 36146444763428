<div class="rs-layout-wrapper">
  <div class="rs-layout-wrapper__header"></div>
  <div class="rs-layout-wrapper__content">
    <ng-content></ng-content>
  </div>
  <div class="rs-layout-wrapper__footer">
    {{ "text.copyright" | translate }} |
    <a href="{{termOfUse}}" target="_blank">{{ "text.terms_of_use" | translate }}</a> |
    <a href="{{privacyPolicy}}" target="_blank">{{ "text.privacy_policy" | translate }}</a>
  </div>
</div>
