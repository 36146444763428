import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { MOBILE_MASK, MOBILE_PREFIX } from 'src/auth/shared/constants/common';
import masks from '../../../../../assets/files/masks.json';
import { Unsubscribe } from '../../../classes/unsubscribe.class';
import { CountryChangeEvent } from '../../../models/country-change.model';

@Component({
  selector: 'rs-phone-control',
  templateUrl: 'phone-control.component.html',
  styleUrls: ['phone-control.component.scss']
})
export class PhoneControlComponent extends Unsubscribe implements OnInit {

  @Input() rsPhoneControl: FormControl;
  @Input() linear: boolean;
  @Output() isControlValid = new EventEmitter<boolean>();

  public middlewareControl: FormControl = new FormControl('', Validators.required);
  public isTouched: boolean;
  public inputOptions = {
    initialCountry: null,
    preferredCountries: ['us', 'br']
  };

  private prefix = MOBILE_PREFIX;
  private mask = MOBILE_MASK;

  ngOnInit() {
    this.subscribeToControl();
    this.setDefaultValue();
  }

  public reactOnClick(): void {
    this.isTouched = true;
  }

  public getPrefix(): string {
    return this.isTouched ? this.prefix : null;
  }

  public getMask(): string {
    return this.isTouched ? this.mask : null;
  }

  public onCountryChange(event: CountryChangeEvent): void {
    if (masks[event.iso2]) {
      const [prefix, mask] = masks[event.iso2]?.split(' ');

      this.prefix = prefix;
      this.mask = mask;
      this.isTouched = true;
    }
  }

  private setDefaultValue(): void {
    if (this.rsPhoneControl) {
      this.isTouched = true;

      setTimeout(() => {
        this.middlewareControl.reset(this.rsPhoneControl.value);
      }, 500);
    }
  }

  private subscribeToControl(): void {
    this.subscribeTo = this.middlewareControl.valueChanges
      .subscribe(() => {
        if(this.middlewareControl.valid) {
          this.isControlValid.emit(true);
        }

        if (this.rsPhoneControl) {
          this.rsPhoneControl.reset({
            countryCode: this.prefix,
            number: `${this.prefix}${this.middlewareControl.value}`
          });
        }
      });
  }

}
