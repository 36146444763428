import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { REGEX } from 'src/auth/shared/constants/regular-expressions';

@Component({
  selector: 'rs-edit-email',
  templateUrl: 'edit-email.component.html',
  styleUrls: ['edit-email.component.scss']
})
export class EditEmailComponent  {

  public emailControl: FormControl = new FormControl( null,
    [
      Validators.required,
      Validators.pattern(REGEX.EMAIL)
    ]
  );

}
