import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Unsubscribe } from 'src/auth/shared/classes/unsubscribe.class';
import { REGEX } from 'src/auth/shared/constants/regular-expressions';
import { PASSWORD_STRENGTH } from 'src/auth/shared/enums/password';

@Component({
  selector: 'rs-password',
  templateUrl: 'password.component.html',
  styleUrls: ['password.component.scss']
})
export class PasswordComponent extends Unsubscribe implements OnInit {

  @ViewChild('password_1') password_1_input: ElementRef;
  @ViewChild('password_2') password_2_input: ElementRef;

  public passwordForm: FormGroup;

  public restricted_password_1: string = '';
  public restricted_password_2: string = '';

  public passwordsInequality: boolean = false;

  public passwordStrength: PASSWORD_STRENGTH;
  public isPasswordLengthCompleted: boolean;
  public isPasswordNumberCompleted: boolean;
  public isPasswordSymbolCompleted: boolean;
  public isPasswordLetterCompleted: boolean;

  constructor(private readonly formBuilder: FormBuilder){
    super();
  }

  public ngOnInit(): void {
    this.initForm();
    this.passwordForm.valueChanges
      .subscribe( ({ password_1, password_2 }) => {
        this.setRestrictedPasswords(password_1, password_2);

        if (password_1) {
          this.checkPasswordStrength(password_1);
        }

      });
  }

  private setRestrictedPasswords(password_1: string, password_2: string): void {
    this.restricted_password_1 = password_1 && password_1.length <= 15 ? password_1 : '';
    this.restricted_password_2 = password_2 && password_2.length <= 15 ? password_2 : '';
  }

  private checkPasswordStrength(password: string): void {
    this.passwordStrength =
      +this.passwordLengthCheck(password) +
      +this.passwordNumberCheck(password) +
      +this.passwordSymbolCheck(password) +
      +this.passwordLetterCheck(password);
  }

  private passwordLengthCheck(password: string): boolean {
    this.isPasswordLengthCompleted = !!password.match(REGEX.EIGHT_CHARACTERS);

    return this.isPasswordLengthCompleted;
  }

  private passwordNumberCheck(password: string): boolean {
    this.isPasswordNumberCompleted = !!password.match(REGEX.NUMBER);

    return this.isPasswordNumberCompleted;
  }

  private passwordSymbolCheck(password: string): boolean {
    this.isPasswordSymbolCompleted = !!password.match(REGEX.SPECIAL_SYMBOL);

    return this.isPasswordSymbolCompleted;
  }

  private passwordLetterCheck(password: string): boolean {
    this.isPasswordLetterCompleted = !!password.match(REGEX.LETTER);

    return this.isPasswordLetterCompleted;
  }

  public isPasswordWeak(): boolean {
    return this.passwordStrength === PASSWORD_STRENGTH.WEAK;
  }

  public isPasswordModerate(): boolean {
    return this.passwordStrength === PASSWORD_STRENGTH.MODERATE;
  }

  public isPasswordStrong(): boolean {
    return this.passwordStrength === PASSWORD_STRENGTH.STRONG;
  }

  public isPasswordVeryStrong(): boolean {
    return this.passwordStrength === PASSWORD_STRENGTH.VERY_STRONG;
  }

  public checkPasswordsEqual(): void {
    this.passwordsInequality = this.passwordForm.touched && this.passwordForm.controls.password_1.value !== this.passwordForm.controls.password_2.value;
  }

  public setInput1AsFocused(inputName: string): void {
    switch (inputName) {
      case 'password_1':
        this.password_1_input.nativeElement.focus();
        console.log(this.password_1_input);
      case 'password_2':
        this.password_1_input.nativeElement.focus();
        console.log(this.password_2_input);
        break;
      default:
        break;
    }
  }

  private initForm(): void {
    this.passwordForm = this.formBuilder.group({
      password_1: [null, [Validators.required]],
      password_2: [null]
    });
  }

  public isInvalidControl(controlName: string): boolean {
    const control = this.passwordForm.get(controlName);
    return control.invalid && (control.dirty || control.touched);
  }

  public isFormComplete(): boolean {
    return (this.passwordForm.controls.password_1.value !== this.passwordForm.controls.password_2.value) ||
      (this.passwordForm.controls.password_2.value === null && !this.isPasswordVeryStrong());
  }

}
