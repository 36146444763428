import { Authentication } from '../shared/models/authentication.model';
import { RecoverPasswordData } from '../shared/models/reset-password.model';
import { SignUpFormData } from '../shared/models/sign-up.model';

export class RecoverPassword {
  static readonly type = '[Password] Recover';

  constructor(
    public recoverPassword: RecoverPasswordData
  ) {}
}

export class ResetPassword {
  static readonly type = '[Password] Reset';

  constructor(
    public email: string
  ) {}
}

export class RefreshSession {
  static readonly type = '[User] Refresh Session';
}

export class Logout {
  static readonly type = '[User] Logout';
}

export class SignIn {
  static readonly type = '[User] Sign In';

  constructor(
    public authentication: Authentication
  ) {}
}

export class SignUp {
  static readonly type = '[User] Sign Up';

  constructor(
    public signUpData: SignUpFormData
  ) {}
}

export class SendActivationLink {
  static readonly type = '[User] Send Activation Link';

  constructor(
    public email: string
  ) {}
}
