import { Injectable } from '@angular/core';

import { Action, State, StateContext } from '@ngxs/store';

import { SetSpinnerVisibility } from './spinner.action';

interface RootStateModel {
  visibility: boolean;
}

@State<RootStateModel>({
  name: 'spinner',
  defaults: {
    visibility: false
  }
})
@Injectable()
export class SpinnerState {

  @Action(SetSpinnerVisibility)
  setSpinnerVisibility({patchState}: StateContext<RootStateModel>, {visibility}: SetSpinnerVisibility) {
    patchState({
      visibility
    });
  }

}
