import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { LayoutWrapperModule } from 'src/auth/shared/components/layout-wrapper/layout-wrapper.module';
import { PhoneControlModule } from 'src/auth/shared/components/phone-control/phone-control.module';
import { VerificationCodeFormModule } from 'src/auth/shared/components/verification-code-form/verification-code-form.module';
import { SharedModule } from 'src/auth/shared/modules/shared.module';
import { MobileNumberComponent } from './component/mobile-number.component';

@NgModule({
  imports: [
    SharedModule,
    LayoutWrapperModule,
    RouterModule,
    VerificationCodeFormModule,
    PhoneControlModule
  ],
  declarations: [
    MobileNumberComponent
  ],
  exports: [
    MobileNumberComponent
  ]
})
export class MobileNumberModule {}
