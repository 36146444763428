import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';

import { SetSpinnerVisibility } from '../components/spinner/ngxs/spinner.action';
import { getErrorContentByCode } from '../helpers';
import { SharedDataService } from '../services/shared-data.service';
import { NOTIFICATION_TYPES } from '../constants/common';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
​
  private store: Store;
  private notifierService: NotifierService;
  private sharedDataService: SharedDataService;
​
  constructor(
    private injector: Injector
  ) {}
​
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.store = this.injector.get(Store);
    this.notifierService = this.injector.get(NotifierService);
    this.sharedDataService = this.injector.get(SharedDataService);
​
    return next.handle(request)
    .pipe(
      catchError((error: HttpErrorResponse, caught: Observable<HttpEvent<any>>) => {
        this.store.dispatch(new SetSpinnerVisibility(false));
        ​this.notifierService.notify(
          NOTIFICATION_TYPES.ERROR,
          this.injector.get(TranslateService).instant(getErrorContentByCode(error.error)) ||
          this.injector.get(TranslateService).instant(getErrorContentByCode(error.error.message)) ||
          'Something happened with the server!'
        );

        throw error;
      })
    );
  }
​
}


