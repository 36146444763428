import { NgModule } from '@angular/core';

import { DirectivesModule } from '../../directives/directives.module';
import { MaterialModule } from '../../modules/material.module';
import { SharedModule } from '../../modules/shared.module';
import { PasswordStrengthModule } from '../password-strength/password-strength.module';
import { PasswordControlComponent } from './password-control.component';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    PasswordStrengthModule,
    DirectivesModule
  ],
  declarations: [
    PasswordControlComponent
  ],
  exports: [
    PasswordControlComponent
  ]
})
export class PasswordControlModule {}
