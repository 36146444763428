<div class="rs-phone-control" [class.rs-phone-control_linear]="linear">
  <input
    [ng2TelInputOptions]="inputOptions"
    (countryChange)="onCountryChange($event)"
    [formControl]="middlewareControl"
    ng2TelInput
    type="text"
    [prefix]="getPrefix()"
    [mask]="getMask()"
    [showMaskTyped]="isTouched"
    placeholder="Mobile Number"
    (click)="reactOnClick()"
    (focus)="reactOnClick()"
  >
  <div class="rs-phone-control__check">
    <mat-icon>check</mat-icon>
  </div>
</div>
