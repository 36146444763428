<rs-layout-wrapper>
  <div class="rs-edit-email">
    <p class="rs-top-label">
      {{ "authentication.edit_email_address" | translate }}
    </p>
    <p class="rs-middle-label">
      {{ "authentication.enter_other_email" | translate }}
    </p>
    <div class="rs-edit-email__form">
      <form class="rs-form-control-outline">
        <mat-form-field appearance="outline">
          <mat-label>{{"placeholders.email" | translate }}</mat-label>
          <input [formControl]="emailControl" matInput [placeholder]="'placeholders.email' | translate">
          <mat-icon matSuffix>check_circle</mat-icon>
          <mat-error *ngIf="emailControl.hasError('required')">
            {{ "validation.required" | translate }}
          </mat-error>
          <mat-error *ngIf="emailControl.hasError('pattern')">
            {{ "validation.email_pattern" | translate }}
          </mat-error>
        </mat-form-field>
      </form>
    </div>
  </div>
</rs-layout-wrapper>
