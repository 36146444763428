import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Subject } from 'rxjs';
import { scale } from '../../animations';

@Component({
  selector: 'rs-password-control',
  templateUrl: 'password-control.component.html',
  styleUrls: ['password-control.component.scss'],
  animations: [
    scale
  ]
})
export class PasswordControlComponent {

  @Output() enterPressed = new EventEmitter<void>();

  @Input() rsPasswordControl: FormControl;
  @Input() placeholder: string;
  @Input() passwordStrength: boolean;

  public passwordSubject: Subject<string> = new Subject<string>();
  public passwordVisibility: boolean;
  public passwordStrengthVisibility: boolean;

  public reactOnPressEnter(): void {
    this.enterPressed.emit();
  }

  public reactOnChangePasswordVisibility(visibility: boolean): void {
    this.passwordVisibility = visibility;
  }

  public setPasswordStrengthVisibility(passwordStrengthVisibility: boolean): void {
    if (this.passwordStrength) {
      this.passwordStrengthVisibility = passwordStrengthVisibility;
      setTimeout(() => {
        this.reactOnPasswordInput();
      });
    }
  }

  public reactOnPasswordInput(): void {
    if (this.passwordStrength) {
      this.passwordSubject.next(this.rsPasswordControl.value);
    }
  }

}
