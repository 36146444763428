import { ChangeDetectionStrategy, Component } from '@angular/core';

import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'rs-auth',
  template: '<router-outlet></router-outlet><rs-spinner *ngIf="spinnerVisibility$ | async"></rs-spinner><notifier-container></notifier-container>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthComponent {

  @Select(state => state.spinner.visibility) spinnerVisibility$: Observable<boolean>;

}
