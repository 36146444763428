import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngxs/store';

import { SignUp } from 'src/auth/ngxs/auth.actions';
import { REGEX } from 'src/auth/shared/constants/regular-expressions';

@Component({
  selector: 'rs-sign-up',
  templateUrl: 'sign-up.component.html',
  styleUrls: ['sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  public signUpForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store
  ){}

  public ngOnInit(): void {
    this.initForm();
  }

  public reactOnSignUp(): void {
    if (this.signUpForm.valid) {
      this.store.dispatch(new SignUp(this.signUpForm.value));
    }
  }

  private initForm(): void {
    this.signUpForm = this.formBuilder.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      email: [null, [Validators.required, Validators.pattern(REGEX.EMAIL)]]
    });
  }

  public isInvalidControl(controlName: string): boolean {
    const control = this.signUpForm.get(controlName);
    return control.invalid && (control.dirty || control.touched);
  }

}
