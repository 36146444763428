import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { COMMON_CONSTANTS } from '../constants/common';
import { HttpLoaderFactory } from '../helpers';

export const TranslateModuleConfig = {
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient]
  },
  defaultLanguage: COMMON_CONSTANTS.DEFAULT_LANGUAGE
}
