import { NgModule } from '@angular/core';

import { SharedModule } from '../../modules/shared.module';
import { SpinnerComponent } from './component/spinner.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    SpinnerComponent
  ],
  exports: [
    SpinnerComponent
  ]
})
export class SpinnerModule {}
