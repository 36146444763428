import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { EditEmailComponent } from './modules/registration/edit-email/component/edit-email.component';
import { PasswordComponent } from './modules/registration/password/component/password.component';
import { MobileNumberComponent } from './modules/registration/mobile-number/component/mobile-number.component';
import { VerificationCodeComponent } from './modules/registration/verification-code/component/verification-code.component';
import { SignUpComponent } from './modules/sign-up/component/sign-up.component';
import { SignInComponent } from './modules/sign-in/sign-in-form/sign-in.component';
import { SignInAlreadyUserComponent } from './modules/sign-in/sign-in-already-user/sign-in-already-user.component';
import { ForgotPasswordComponent } from './modules/sign-in/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './modules/reset-password/reset-password.component';

@NgModule({
  imports: [
    RouterModule.forRoot([
      { path: '', redirectTo: '/sign-in', pathMatch: 'full' },
      { path: 'sign-in', component: SignInComponent },
      { path: 'sign-up', component: SignUpComponent },
      { path: 'already-user', component: SignInAlreadyUserComponent },
      { path: 'verification-code', component: VerificationCodeComponent },
      { path: 'edit-email', component: EditEmailComponent },
      { path: 'password', component: PasswordComponent },
      { path: 'mobile-number', component: MobileNumberComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'resetPassword', component: ResetPasswordComponent },
      { path: '**', component: SignInComponent }
    ])
  ],
  exports: [
    RouterModule
  ]
})
export class AuthRoutingModule {}
