import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from 'src/auth/shared/modules/shared.module';
import { SignUpComponent } from './component/sign-up.component';

@NgModule({
  imports: [
    SharedModule,
    RouterModule
  ],
  declarations: [
    SignUpComponent
  ],
  exports: [
    SignUpComponent
  ]
})
export class SignUpModule {}
