import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { LayoutWrapperModule } from 'src/auth/shared/components/layout-wrapper/layout-wrapper.module';
import { VerificationCodeFormModule } from 'src/auth/shared/components/verification-code-form/verification-code-form.module';
import { SharedModule } from 'src/auth/shared/modules/shared.module';
import { VerificationCodeComponent } from './component/verification-code.component';

@NgModule({
  imports: [
    SharedModule,
    LayoutWrapperModule,
    RouterModule,
    VerificationCodeFormModule
  ],
  declarations: [
    VerificationCodeComponent
  ],
  exports: [
    VerificationCodeComponent
  ]
})
export class VerificationCodeModule {}
