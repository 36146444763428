export const ENDPOINTS = {
  SIGN_IN: 'authentication/v1/user/login',
  SIGN_UP: 'user-profile/v1/profile/join',
  SEND_ACTIVATION_LINK: 'user-profile/v1/profile/validate',
  REFRESH_TOKEN: 'authentication/v1/user/login/refresh',
  RESET_PASSWORD: 'authentication/v1/recovery/password',
  CONFIRM_PASSWORD: 'authentication/v1/recovery/password/confirm',
  PROFILE_ACTIVATION: '/user-profile/v1/profile/join/validate',
  RECOVER_PASSWORD: 'authentication/v1/recovery/password/confirm'
};
