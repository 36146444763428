import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { JOB_POSITIONS, USER_NAME } from 'src/auth/shared/constants/common';

@Component({
  selector: 'rs-sign-in-already-user',
  templateUrl: 'sign-in-already-user.component.html',
  styleUrls: ['sign-in-already-user.component.scss']
})
export class SignInAlreadyUserComponent implements OnInit{

  public userName: string = USER_NAME;
  public alreadyUserForm: FormGroup;
  //TODO: Remove hardcoded values below
  public positions: string[] = JOB_POSITIONS;

  constructor(private readonly formBuilder: FormBuilder){}

  public ngOnInit(): void {
    this.initForm()
  }

  private initForm(): void {
    this.alreadyUserForm = this.formBuilder.group({
      restaurantName: [null, [Validators.required]],
      position: [null, [Validators.required]]
    });
  }

  public isInvalidControl(controlName: string): boolean {
    const control = this.alreadyUserForm.get(controlName);
    return control.invalid && (control.dirty || control.touched);
  }

}
