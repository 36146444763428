import { NgModule } from '@angular/core';

import { EditEmailModule } from './registration/edit-email/edit-email.module';
import { PasswordModule } from './registration/password/password.module';
import { MobileNumberModule } from './registration/mobile-number/mobile-number.module';
import { VerificationCodeModule } from './registration/verification-code/verification-code.module';
import { SignUpModule } from './sign-up/sign-up.module';
import { SignInModule } from './sign-in/sign-in.module';
import { ResetPasswordModule } from './reset-password/reset-password.module';

@NgModule({
  exports: [
    SignInModule,
    SignUpModule,
    VerificationCodeModule,
    EditEmailModule,
    PasswordModule,
    MobileNumberModule,
    ResetPasswordModule
  ]
})
export class ModulesModule {}
