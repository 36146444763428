import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ENDPOINTS } from './shared/constants/endpoints';
import { Authentication, AuthenticationData } from './shared/models/authentication.model';
import { IdResponse } from './shared/models/id-response.model';
import { RecoverPasswordData } from './shared/models/reset-password.model';
import { SignUpData } from './shared/models/sign-up.model';
import { ApiService } from './shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private apiService: ApiService
  ) {}

  public recoverPassword(recoverPassword: RecoverPasswordData): Observable<void> {
    return this.apiService.post(ENDPOINTS.RECOVER_PASSWORD, recoverPassword);
  }

  public resetPassword(username: string): Observable<void> {
    return this.apiService.post(ENDPOINTS.RESET_PASSWORD, {username, client: 'REGP'});
  }

  public refreshToken(refreshToken: string): Observable<AuthenticationData> {
    return this.apiService.post(ENDPOINTS.REFRESH_TOKEN, {refreshToken});
  }

  public sendActivationLink(email: string): Observable<void> {
    return this.apiService.post(ENDPOINTS.SEND_ACTIVATION_LINK, {email});
  }

  public signIn(authentication: Authentication): Observable<AuthenticationData> {
    return this.apiService.post(ENDPOINTS.SIGN_IN, authentication);
  }

  public signUp(signUpData: SignUpData): Observable<IdResponse> {
    return this.apiService.post(ENDPOINTS.SIGN_UP, signUpData);
  }

}
